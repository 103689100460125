import React from "react"



const Block = ({children, size, image, classes, id}) => {
    const backgroundImage = {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center"
    }

    return <div className={ "wrapper bg-wrapper " + classes} style={image != null ? backgroundImage: null} id={id}>
        <div className={"container " + size}>
            {children}
        </div>
    </div>
}

export default Block
